import { useRefreshHPayBalance } from "../state/investor-info";
import { ContractAddresses, useContractWithProvider, __WEB_3__ } from "./contracts/contracts";
import Transfer from "hpay/contracts/Transfer.json";

export const useTransferContract = (provider = __WEB_3__) => {
    const contract = useContractWithProvider(Transfer.address, Transfer.abi, provider);
    return contract;
};

export const useTransferActions = (account) => {

    const transferContract = useTransferContract(window.web3);
    const [, refreshInvestorBalance] = useRefreshHPayBalance();
    const transfer = async (amount, destination) => {

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        
        const addr = window.web3.utils.toChecksumAddress(destination)
        await transferContract.methods.transfer(ContractAddresses.HPAY, window.web3.utils.toWei('' + amount), addr).estimateGas(args);
        const result = transferContract.methods.transfer(ContractAddresses.HPAY, window.web3.utils.toWei('' + amount), addr).send(args);
        return result.then(async result => {
            refreshInvestorBalance(account);
            return result;
        });
    };


    return { transfer };

};
