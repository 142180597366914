import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Transfer from "../containers/transfers.js";

const Transfers = () => {

	return (
		<Layout pageInfo={{ pageName: "transfers" }}>
			<ScrollUpButton ContainerClassName="custom-scroll-button" />
			<Seo title="H-Pad" />

			<Row noGutters>
				<Col>
					<Container className="mt-5">
						<section id="transfer">
							<Row className="mb-md-5 mb-4">
								<Col md={8} >
									<h2 className="title-seperator">HedgePay Transfer Gateway</h2>
									<p>
										Transfer HedgePay without tax
									</p>
								</Col>
							</Row>
							<Transfer></Transfer>
						</section>
					</Container>
				</Col>
			</Row>


		</Layout>
	);
};

export default Transfers;
