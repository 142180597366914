import React, { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import NetworkContext from '../../context/network-context';
import { useTransferActions } from "../../web3/transfers";
import ExpandIcon from '../../images/expand-icon.png';

function TransferConfirm({ receiver, amount, onConfirm }) {
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { transfer } = useTransferActions(account);
    const [errorMessage, setErrorMessage] = useState();
    const [receipt, setReceipt] = useState();


    const confirmTransaction = async () => {
        let tx = async () => await transfer(amount, receiver);
        setErrorMessage(null);
        try {
            const result = await executeTransaction({
                message: 'Transferring',
                tx,
                throwErr: true
            });

            setReceipt(result.receipt.transactionHash);
        } catch (error) {
            console.log(error);
            setErrorMessage("Something went wrong");
        }
    };

    const success = <>
        <Row className="flex-column ">
            <Col md={12} className="mb-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>


            </Col>
            <Col md={12} className="text-center">
                <span>Success</span>
                <p>
                    <a href={"https://bscscan.com/tx/" + receipt} target="_blank" rel="noopener noreferrer">

                        {receipt}
                        <img src={ExpandIcon} className="ml-2 mr-0" alt="icon" />
                    </a>
                </p>
                <Button disabled={pendingTransaction || !connected || !isBsc}
                    onClick={() => onConfirm()} className="mt-3" block>
                    Done
                </Button>
            </Col>
        </Row>
    </>;

    const confirm = <>
        <Row >
            <Col md={12} className="mb-4 d-flex justify-content-between">
                <div className="exchange-group">
                    <img
                        src={'/hpay.svg'}
                        alt={'HedgePay'}
                        width={40}
                        height={40}
                    />
                    <div className="exchange-group-input">
                        <span className="exchange-confirm-value-item ">
                            <NumberFormat value={amount.toFixed(8)} displayType={'text'} thousandSeparator={true} />
                        </span>
                    </div>
                </div>
                <span className="exchange-confirm-currency " >HPAY </span>
            </Col>

            <Col md={12} className="mb-4 d-flex justify-content-between">
                <div className="exchange-group-input text-wrap ml-0">
                    <small className="exchange-confirm-value-label">To</small>
                    <span className="exchange-confirm-value-item " style={{ wordBreak: 'break-all' }}>
                        {receiver}
                    </span>
                </div>
            </Col>

        </Row>

        <Button disabled={pendingTransaction || !connected || !isBsc}
            onClick={() => confirmTransaction()} className="mt-3" block>
            {!pendingTransaction ? "Confirm" : <>

                Sending...

                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />

            </>}
        </Button>
        {errorMessage && <p className="mb-0 mt-2 text-center">{errorMessage}</p>}
    </>;

    return (
        <div className="staking-input-component">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '1rem' }} noGutters>
                        <Col md={12} className=" text-center ">
                            <h3 className="text-white ">Transfer</h3>
                        </Col>
                    </Row>
                    {receipt ? success : confirm}
                </Card.Body >
            </Card >
        </div >
    );
}

export default TransferConfirm;
